import React from "react";
import { FollowupEntityType } from "us.collection.admin/constants";
import { $Alert } from "us.common/components";
import { i18n } from 'us.helper';
/**
 * @function
 * @description - format example query
 * @param {string} exampleQuery  - Exmaple Query comes from API response
 * @returns formatted Query string
 */
export const formatExampleQuery = (exampleQuery: string): JSX.Element => {
  try {
    const obj =   {
      "text": exampleQuery,
      "highlight": [
        {
            "targetStrings": ["SELECT" ],
            "color": "#ffa310",
            "fontWeight": "bold"
        },
        {
            "targetStrings": [ "FROM" ],
            "color": "#5a5ae2",
            "fontWeight": "bold"
        },
        {
            "targetStrings": [ "INNER", "OUTER", "JOIN" ],
            "color": "#e93fe3",
            "fontWeight": "bold"
        },
        {
            "targetStrings": [ "ON" ],
            "color": "#e9673f",
            "fontWeight": "bold"
        },
        {
            "targetStrings": [ "WHERE" ],
            "color": "#57d357",
            "fontWeight": "bold"
        }
      ]
    }

    const coloredWords = obj.text.split(" ").map(word => {
      const color = obj.highlight.reduce((acc, item) => {
          const index = item.targetStrings.indexOf(word)
          if (index >= 0) return item.color
          return acc
      }, '')
      const fontWeight = obj.highlight.reduce((acc, item) => {
          const index = item.targetStrings.indexOf(word)
          if (index >= 0) return item.fontWeight
          return acc
      }, '')
      return { word, color, fontWeight }
    })

    return (
      <>
        {coloredWords.map(item => (
          <span
            key={item.word}
            style={{
              "color": item.color,
              "fontWeight": item.fontWeight
            }}
          >
            {item.word}{" "}
          </span>
        ))}
      </>
    );
  } catch (error) {
    return <></>;
  }
};

/**
 * @function
 * @description Display relevent user message to guide to write correct query considering entity type
 * @param entityType entityType
 * @returns Return relevent user message for entity type 
 */

export const handleFollowupUserMessage = (
  entityType: string,
): any => {
  try {
    switch (entityType) {
      case FollowupEntityType.INVOICE:
        return (<>
          <$Alert
            className="mt-2"
            message={
              <span>
                {i18n.t("US.COLLECTION.ADMIN:BULKOPERATION.CASE_MESSAGE")}
              </span>
            }
            type="info"
            showIcon
          />
        </>)
      case FollowupEntityType.CASE:
        return (<>
          <$Alert
            className="mt-2"
            message={
              <span>
                {i18n.t("US.COLLECTION.ADMIN:BULKOPERATION.CASE_MESSAGE")}
              </span>
            }
            type="info"
            showIcon
          />
        </>)
      case FollowupEntityType.DEBTOR:
        return (<>
          <$Alert
            className="mt-2"
            message={
              <span>

                {i18n.t("US.COLLECTION.ADMIN:BULKOPERATION.AR_MESSAGE")}
              </span>
            }
            type="info"
            showIcon
          />
        </>)

      default:
        return (<></>)
    }

  } catch {
    return (<></>);
  }

};
