import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { matchPath, useLocation } from "react-router-dom";
import { IRootState } from "us.collection/interfaces";
import {
  $TextArea,
  $Switch,
  $Skeleton,
  $Button,
  $Drawer,
  $SQLEditor,
} from "us.common/components";
import {
  ResultView,
  SaveQuery,
} from "us.collection.admin/components/ManageFollowup/CustomQuery/Components";
import { manageFollowupActions } from "us.collection.admin/actions";
import { formatExampleQuery, handleFollowupUserMessage } from "us.collection.admin/functions";
import {
  CustomQueryDrawerType,
  InitialCustomQueryDrawer,
} from "us.collection.admin/constants";
import { QuerySpaceSchema } from "./Validations";

const { customQuery, followUpDetails } = manageFollowupActions;

/**
 * @description - Manage Follow-up Query Space Component
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2942664743/Follow-up+Custom+Query+Workspace+UI+Implementation
 * @authors Mahesh Suranga <maheshsu@unicorn-solutions.com>
 * @since 05/04/2022
 */
const QuerySpace: React.FC<PropsFromRedux> = (props) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const {
    getCustomQueryProperties,
    manageDrawer,
    executeCustomQuery,
    properties,
    drawer,
    isNewCustomQuery,
    followUpDetails,
  } = props;

  const { data, isFetching } = properties;
  const { sampleQuery } = data;
  const { title, type, visible } = drawer;
  const { data: followUpDetailsData, isFetching: followUpDetailsFetching } =
    followUpDetails;
  const [entityType,setEntityType] = useState<string>('')

  useEffect(() => {
    const { params }: any = matchPath(pathname, {
      path: "/manage-followup/custom-query/:entityType?",
      exact: true,
      strict: false,
    });
    const entityType = params["entityType"];
    setEntityType(entityType)
    entityType &&
      getCustomQueryProperties &&
      getCustomQueryProperties({ entityType });
  }, [pathname]);

  /**
   * @function
   * @description - function when fire the event of save or result view button click
   * @param {string} customQuery - sql query
   * @param {CustomQueryDrawerType} type - drawer type
   * @returns void
   */
  const handleOnClick = (
    customQuery: string,
    type: CustomQueryDrawerType
  ): void => {
    type == CustomQueryDrawerType.SAVE_QUERY &&
      manageDrawer &&
      manageDrawer({
        ...InitialCustomQueryDrawer,
        title: t("US.COLLECTION.ADMIN:BULKOPERATION.SAVE_QUERY"),
        type,
      });
    if (type == CustomQueryDrawerType.VIEW_RESULT) {
      const { params }: any = matchPath(pathname, {
        path: "/manage-followup/custom-query/:entityType?",
        exact: true,
        strict: false,
      });
      const entityType = params["entityType"];
      executeCustomQuery &&
        executeCustomQuery({
          customQuery,
          entityType,
          type,
          title: t("US.COLLECTION.ADMIN:BULKOPERATION.VIEW_RESULT"),
        });
    }
  };

  /**
   * @function
   * @description - drawer close function of save query and view result UI
   * @returns void
   */
  const closeDrawer = (): void => {
    manageDrawer &&
      manageDrawer({
        ...InitialCustomQueryDrawer,
        title: "",
        type: CustomQueryDrawerType.VIEW_RESULT,
        visible: false,
      });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...data,
        customQuery: followUpDetailsData.customQuery,
        isStaticWorkFlow: !followUpDetailsData.isDynamicFollowup,
      }}
      validationSchema={QuerySpaceSchema}
      onSubmit={(values: any, actions: any) => {}}
      validateOnMount
      validateOnChange
      validateOnBlur
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        ...rest
      }: any) => (
        <$Skeleton
          className="p-3"
          loading={isFetching}
          paragraph={{ rows: 2 }}
          active
        >
          <>
            <div className="d-flex flex-column flex-grow-1 csql-body">
              <div className="mt-3 pl-3 d-flex flex-1 align-items-top">
                <div className="mr-2">
                  <$Switch
                    name="isStaticWorkFlow"
                    checked={values?.isStaticWorkFlow}
                    disabled={!isNewCustomQuery}
                    data-testid="isStaticWorkFlow-switch"
                  />
                </div>
                <div style={{ paddingTop: 3 }}>
                  {t("US.COLLECTION.ADMIN:BULKOPERATION.WORKFLOW_FOLLOWUP")}
                </div>
              </div>
              <div className="csql-example">
                <div>
                  <strong>
                    {t("US.COLLECTION.ADMIN:BULKOPERATION.EXAMPLE")} :{" "}
                  </strong>
                  {formatExampleQuery(sampleQuery)}
                  <div>           
                  {handleFollowupUserMessage(entityType)}
                  </div>
                </div>
              </div>
              <div className="flex-grow-1 csql-textarea-wrap">
                <$SQLEditor
                  name={"customQuery"}
                  className="csql-textarea"
                  disabled={!isNewCustomQuery && values?.isStaticWorkFlow}
                  data-testid="customQuery-textArea"
                  required
                />
              </div>
            </div>
            <div className="csql-footer">
              <$Button
                type="primary"
                onClick={() =>
                  handleOnClick(
                    values?.customQuery,
                    CustomQueryDrawerType.SAVE_QUERY
                  )
                }
                className="mr-2"
                disabled={!rest.isValid}
                data-testid="save-btn"
              >
                {t("US.COLLECTION.COMMON:COMMON.SAVE")}
              </$Button>
              <$Button
                onClick={() =>
                  handleOnClick(
                    values?.customQuery,
                    CustomQueryDrawerType.VIEW_RESULT
                  )
                }
                disabled={!rest.isValid || values?.isStaticWorkFlow}
                data-testid="viewResult-btn"
              >
                {t("US.COLLECTION.ADMIN:BULKOPERATION.VIEW_RESULT")}
              </$Button>
            </div>
          </>
          <$Drawer
            title={title}
            width={1000}
            visible={visible}
            onClose={() => closeDrawer()}
            destroyOnClose
            className={
              type == CustomQueryDrawerType.SAVE_QUERY
                ? "csql-save-query"
                : "csql-view-result"
            }
          >
            {type == CustomQueryDrawerType.SAVE_QUERY && (
              <SaveQuery
                customQuery={values?.customQuery}
                isStaticWorkFlow={values?.isStaticWorkFlow}
                onClose={closeDrawer}
              />
            )}
            {type == CustomQueryDrawerType.VIEW_RESULT && <ResultView onClose={closeDrawer}/>}
          </$Drawer>
        </$Skeleton>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { manageFollowups } = state;
  const { customQuery, followUpDetails } = manageFollowups;
  const { properties, drawer, isNewCustomQuery } = customQuery;
  return { properties, drawer, isNewCustomQuery, followUpDetails };
};

const mapDispatchToProps = {
  getCustomQueryProperties: customQuery.getProperties,
  manageDrawer: customQuery.manageDrawer,
  executeCustomQuery: customQuery.execute,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(QuerySpace);
